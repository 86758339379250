// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-form-confirmacao-js": () => import("./../../../src/pages/form/confirmacao.js" /* webpackChunkName: "component---src-pages-form-confirmacao-js" */),
  "component---src-pages-form-index-js": () => import("./../../../src/pages/form/index.js" /* webpackChunkName: "component---src-pages-form-index-js" */),
  "component---src-pages-form-type-js": () => import("./../../../src/pages/form/type.js" /* webpackChunkName: "component---src-pages-form-type-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentoria-pensamental-10-x-confirmacao-js": () => import("./../../../src/pages/mentoria-pensamental-10x/confirmacao.js" /* webpackChunkName: "component---src-pages-mentoria-pensamental-10-x-confirmacao-js" */),
  "component---src-pages-mentoria-pensamental-10-x-index-js": () => import("./../../../src/pages/mentoria-pensamental-10x/index.js" /* webpackChunkName: "component---src-pages-mentoria-pensamental-10-x-index-js" */),
  "component---src-pages-privacidade-js": () => import("./../../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */)
}

